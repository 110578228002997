import { getGravatar } from '@/js/utils.js'
import { generateGetters, generateMutations } from '../helpers'

const state = {
  userFriends: [],
  pendingRequests: []
}

const getters = generateGetters(state)

const mutations = generateMutations(state)

const actions = {
  getFriends ({ state, commit, dispatch }, socket) {
    return new Promise((resolve, reject) => {
      socket.emit('get-friends', null, function (results) {
        const friends = []

        for (let idx = 0; idx < results.length; idx++) {
          const result = results[idx]
          result.imgSrc = getGravatar(result.emailHash, 64)
          friends.push(result)
        }
        commit('userFriends', friends)
        resolve()
      })
    })
  },
  createRoomWithFriends ({ state }, { socket, friends, controllers, roomName, eventType, watchPartyName, watchPartyTimestamp, watchPartyDuration }) {
    return new Promise((resolve, reject) => {
      if (!(friends instanceof Array)) {
        friends = [friends]
      }
      const participants = []
      friends.forEach(friend => {
        if (friend.emailHash) {
          participants.push({ type: 'encrypted', value: friend.emailHash })
        } else {
          participants.push({ type: 'plain', value: friend.email })
        }
      })
      socket.emit('create-room', {
        participants,
        controllers,
        roomName,
        eventType,
        watchPartyName,
        watchPartyTimestamp,
        watchPartyDuration
      }, function (json) {
        resolve(json)
      })
    })
  },
  inviteFriendsToRoom ({ state }, { friends, socket }) {
    return new Promise((resolve, reject) => {
      if (!(friends instanceof Array)) {
        friends = [friends]
      }
      const participants = friends.map(friend => ({ type: 'encrypted', value: friend.emailHash }))
      socket.emit('invite-friends-to-room', {
        participants
      }, function (json) {
        resolve(json)
      })
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
