import { isMobile, isTablet, isAndroid, isChrome, isFirefox, isEdge, isOpera } from '@/js/is'

const getters = {
  isMobile,
  isAndroid,
  isChrome,
  isFirefox,
  isEdge,
  isOpera,
  isTablet
}

export default {
  getters
}
