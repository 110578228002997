// import GoogleAnalytics from './google-analytics'
import PlausibleAnalytics from './plausible-analytics'

const backends = [
  // new GoogleAnalytics(),
  new PlausibleAnalytics()
]

async function initialize () {
  const promises = backends.map(x => x.initialize())
  try {
    await Promise.all(promises)
  } catch (e) {
  }
}

async function log (category, action, label, value) {
  return Promise.all(backends.map(x => x.log(category, action, label, value)))
}

async function updatePage (to) {
  return Promise.all(backends.map(x => x.updatePage(to)))
}

function install (Vue) {
  Vue.prototype.$analytics = { log }
}

export default {
  initialize,
  log,
  updatePage,
  install
}
