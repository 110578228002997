import Vue from 'vue'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import Visibility from 'visibilityjs/lib/visibility.core'
import { toast } from '@/js/utils.js'
import { generateGetters, generateMutations, illegalMutation } from '../helpers'

const state = {
  rawMessages: {},
  messageGroupsList: [],
  pinnedMessages: {},
  groupings: {},
  unreadMessages: [],
  showingDiscussion: false,
  typedMessage: '',
  lastNotified: 0,
  muteAudioNotifications: false,
  userJoinLeaveTextNotification: true,
  mergeMessagesOnDelete: false,
  typingUsers: {},
  typingTimeouts: [],
  recentlyUsedEmojis: [],
  typedEmojis: []
}

const getters = {
  ...generateGetters(state),
  typingUsersArray: state => Object.values(state.typingUsers)
}

const mutations = {
  ...generateMutations(state),
  groupings: illegalMutation,
  mergeMessagesOnDelete: illegalMutation,
  typingUsers: illegalMutation,
  typingTimeouts: illegalMutation
}

const actions = {
  resetTextChat ({ commit, state }) {
    commit('rawMessages', {})
    commit('messageGroupsList', [])
    commit('unreadMessages', [])
    commit('showingDiscussion', false)
  },
  async getUserFromUserHash ({ dispatch, getters }, userHash) {
    const { socket, roomUsers } = getters
    await new Promise(resolve => {
      socket.emit('userInfo-from-userHash', userHash, async (userInfo) => {
        console.log('Received userInfo for userHash', userHash)
        Vue.set(roomUsers, userInfo.userHash, userInfo)
        resolve()
        dispatch('fetchAvatar', userHash).then(avatar => {
          Vue.set(roomUsers[userInfo.userHash], 'avatar', avatar)
        })
      })
    })
    return roomUsers[userHash]
  },
  async addMessage ({ state, dispatch, getters }, { msg, selfHash, notifications }) {
    notifications = notifications || { audio: true }
    const { roomUsers } = getters
    const { uuid, sender, userHash } = msg
    const system = userHash === 'system'
    msg.system = system
    if (!Object.prototype.hasOwnProperty.call(roomUsers, msg.userHash) && !system) {
      // get user info from sahvah
      await dispatch('getUserFromUserHash', msg.userHash)
    }
    const { rawMessages, messageGroupsList } = state
    // First, add it to rawMessages
    Vue.set(rawMessages, uuid, msg)

    msg.time = moment.utc(msg.time).local()
    const { nomerge = false, time, toast: toastMessage } = msg
    let addAsNewGroup = true
    const lastGroupEntry = messageGroupsList[messageGroupsList.length - 1] || {}
    const { userHash: lastUserHash, time: lastTime } = lastGroupEntry

    let group
    if (lastUserHash === userHash) {
      const diffSeconds = time.diff(lastTime, 'seconds')
      // console.log(`msgTime=${msgTime} lastGroupEntryTime=${lastMessageTime} seconds=${diffSeconds}`)
      if (!nomerge && diffSeconds <= 10 * 60) {
        addAsNewGroup = false
        group = lastGroupEntry
        const lastMessage = group.messages[group.messages.length - 1] || {}
        const newMsgEntry = { uuid, previousMessage: lastMessage }
        group.messages.push(newMsgEntry)
        lastGroupEntry.time = time
        lastMessage.nextMessage = newMsgEntry
      }
    }
    if (addAsNewGroup) {
      const newGroup = {}
      Object.assign(newGroup, {
        uuid: uuidv4(),
        sender,
        userHash,
        time,
        editing: undefined,
        messages: [],
        previousGroup: lastGroupEntry
      })
      const msgEntry = { uuid }
      newGroup.messages.push(msgEntry)
      group = newGroup
      state.messageGroupsList.push(newGroup)
      lastGroupEntry.nextGroup = newGroup
    }
    state.groupings[uuid] = group
    if ((!state.showingDiscussion || Visibility.hidden()) && msg.userHash !== selfHash) {
      state.unreadMessages.push(uuid)
      const { roomProperties: { isEvent } } = getters
      if (!isEvent && notifications.audio) {
        dispatch('notifyUser')
      }
    }
    if (toastMessage) {
      toast(toastMessage)
    }
  },
  addSystemMessage ({ state, commit, dispatch }, { msg: message, selfHash, notifications }) {
    const msg = {
      uuid: uuidv4(),
      userHash: 'system',
      nomerge: true,
      msg: message,
      time: moment().utc().valueOf(),
      system: true
    }
    dispatch('addMessage', {
      msg,
      selfHash,
      notifications
    })
  },
  clearUnreadMessages ({ state }) {
    state.unreadMessages = []
  },
  toggleDiscussion ({ dispatch, state, commit }) {
    commit('showingDiscussion', !state.showingDiscussion)
    if (state.showingDiscussion) {
      dispatch('clearUnreadMessages')
    }
  },
  async deleteChatMessage ({ state, dispatch, commit }, { uuid }) {
    const { mergeMessagesOnDelete, rawMessages, groupings, messageGroupsList } = state
    const groupEntry = groupings[uuid]
    if (groupEntry) {
      const { messages } = groupEntry
      if (messages.length === 1) {
        // All messages in this group of messages are to be deleted.
        // Just remove the entire group
        const { previousGroup = {}, nextGroup = {} } = groupEntry
        previousGroup.nextGroup = nextGroup
        nextGroup.previousGroup = previousGroup
        messageGroupsList.splice(messageGroupsList.indexOf(groupEntry), 1)
        if (mergeMessagesOnDelete && previousGroup.userHash === nextGroup.userHash) {
          // We need to merge these together
          const { messages: previousGroupMessages } = previousGroup
          const lastMessage = previousGroupMessages[previousGroupMessages.length - 1] || {}
          const nextMessage = nextGroup.messages[0] || {}
          lastMessage.nextMessage = nextMessage
          nextMessage.previousMessage = lastMessage
          previousGroupMessages.push(...nextGroup.messages)
          previousGroup.time = nextGroup.time
        }
      } else {
        // We only delete from messages if messages is not going to be empty.
        // If the entire group is to be deleted, why bother deleting a message
        const messageIndex = messages.indexOf(messages.find(x => x.uuid === uuid))
        const messageToDelete = messages[messageIndex]
        const { previousMessage = {}, nextMessage = {} } = messageToDelete
        previousMessage.nextMessage = nextMessage
        nextMessage.previousMessage = previousMessage
        messages.splice(messageIndex, 1)
      }
    }
    Vue.delete(rawMessages, uuid)
  },

  async notifyUser ({ state, commit, dispatch }) {
    const now = moment().valueOf()
    const shouldNotify = !state.muteAudioNotifications && (now - state.lastNotified) > 5000
    if (shouldNotify) {
      // Notify user
      dispatch('playAudioNotification')
      commit('lastNotified', now)
    }
  },
  playAudioNotification () {
    const audio = new Audio('/static/media/chat-notification.mp3')
    audio.play()
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
