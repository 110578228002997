import Vue from 'vue'
import { generateGetters, generateMutations } from '../helpers.js'

import { VueOrderedData } from '@/js/ordered-data.js'

const state = {
  roomInvitations: new VueOrderedData([], 'roomName', Vue)
}

const getters = generateGetters(state)

const mutations = generateMutations(state)

export default {
  state,
  getters,
  mutations
}
