import { generateGetters, generateMutations } from '../helpers'

const state = {
  searchResultsSearching: false,
  searchResultsClosed: false,
  searchResults: [],
  youtubeResults: [],
  vimeoResults: []
}

const getters = generateGetters(state)

const mutations = generateMutations(state)

const actions = {
  searchStarted ({ dispatch, commit, state }) {
    commit('searchResults', [])
    commit('searchResultsSearching', true)
    commit('searchResultsClosed', false)
  },
  searchFinished ({ dispatch, commit, state }, results) {
    commit('searchResultsSearching', false)
    commit('searchResultsClosed', false)
    commit('searchResults', results)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
