const MediaEventTypes = Object.freeze({
  MEDIA_CREATE: 'MEDIA_CREATE',
  MEDIA_PLAY: 'MEDIA_PLAY',
  MEDIA_PAUSE: 'MEDIA_PAUSE',
  MEDIA_SEEK: 'MEDIA_SEEK',
  MEDIA_INTERACTIVE_CHOICE: 'MEDIA_INTERACTIVE_CHOICE',
  MEDIA_ADD_CAPTIONS: 'MEDIA_ADD_CAPTIONS',
  MEDIA_DESTROY: 'MEDIA_DESTROY'
})

const UserEventTypes = Object.freeze({
  USER_JOIN: 'USER_JOIN',
  USER_LEAVE: 'USER_LEAVE',
  USER_DISCONNECT: 'USER_DISCONNECT',
  USER_UPDATE_PROFILE: 'USER_UPDATE_PROFILE'
})

const RoomTypes = Object.freeze({
  DEMOCRATIC: 'DEMOCRATIC',
  DICTATOR: 'DICTATOR'
})
const CreateRoomTypes = Object.freeze({
  NOW: 'NOW',
  SCHEDULE: 'SCHEDULE'
})

const RoomStatusTypes = Object.freeze({
  ACTIVE: 'ACTIVE', // In memory.. someone in the room right now
  INACTIVE: 'INACTIVE' // Frozen, nobody in room
})

const InvitationActionTypes = Object.freeze({
  UPDATE: 'UPDATE', // Something about the invitation/room has been updated
  DELETE_ROOM: 'DELETE_ROOM', // The room has been deleted
  DELETE_INVITE: 'DELETE_INVITE' // The invitation has been deleted
})

const RoomChatTypes = Object.freeze({
  ALL_CHAT: 'ALL_CHAT',
  MOD_CHAT: 'MOD_CHAT',
  NO_CHAT: 'NO_CHAT'
})

const RoomPlaybackControlsTypes = Object.freeze({
  SELF_CONTROLS: 'SELF_CONTROLS',
  MOD_CONTROLS: 'MOD_CONTROLS'
})

const _RoomEventTypes = {
  ROOM_TYPE_UPDATE: 'ROOM_TYPE_UPDATE',
  ROOM_OWNER_UPDATE: 'ROOM_OWNER_UPDATE',
  ROOM_DD_UPDATE: 'ROOM_DD_UPDATE',
  ROOM_PROPERTIES_UPDATE: 'ROOM_PROPERTIES_UPDATE',
  ROOM_METADATA_UPDATE: 'ROOM_METADATA_UPDATE',
  ROOM_SOURCE_CHANGE: 'ROOM_SOURCE_CHANGE',
  ROOM_MEDIA_UPDATE: 'ROOM_MEDIA_UPDATE',
  ROOM_REACTION_UPDATE: 'ROOM_REACTION_UPDATE',
  ROOM_PLAYLIST_ADD: 'ROOM_PLAYLIST_ADD',
  ROOM_PLAYLIST_REMOVE: 'ROOM_PLAYLIST_REMOVE',
  ROOM_PLAYLIST_MOVE: 'ROOM_PLAYLIST_MOVE',
  ROOM_PLAYLIST_UPDATE: 'ROOM_PLAYLIST_UPDATE',
  ROOM_ADD_CONTROLLER: 'ROOM_ADD_CONTROLLER',
  ROOM_REMOVE_CONTROLLER: 'ROOM_REMOVE_CONTROLLER',
  ROOM_BAN_USER: 'ROOM_BAN_USER',
  ROOM_UNBAN_USER: 'ROOM_UNBAN_USER',
  ROOM_CHAT_MESSAGE: 'ROOM_CHAT_MESSAGE',
  ROOM_EDIT_CHAT_MESSAGE: 'ROOM_EDIT_CHAT_MESSAGE',
  ROOM_DELETE_CHAT_MESSAGE: 'ROOM_DELETE_CHAT_MESSAGE',
  ROOM_PINNED_MESSAGE_UPDATE: 'ROOM_PINNED_MESSAGE_UPDATE',
  ROOM_DESTROY: 'ROOM_DESTROY',
  ROOM_MEDIA_RESYNC: 'ROOM_MEDIA_RESYNC',
  ROOM_REACTION_REACT: 'ROOM_REACTION_REACT',
  ROOM_PLAYLIST_GET: 'ROOM_PLAYLIST_GET',
  ROOM_MARKED_FOR_REMOVAL: 'ROOM_MARKED_FOR_REMOVAL',
  ...MediaEventTypes,
  ...UserEventTypes
}
delete _RoomEventTypes.USER_DISCONNECT // room does not receive this event
const RoomEventTypes = Object.freeze(_RoomEventTypes)

const SessionEventTypes = Object.freeze({
  SESSION_CREATE: 'SESSION_CREATE',
  SESSION_BIND: 'SESSION_BIND',
  SESSION_UNBIND: 'SESSION_UNBIND',
  ...RoomEventTypes
})

export {
  MediaEventTypes,
  UserEventTypes,
  RoomEventTypes,
  RoomTypes,
  RoomStatusTypes,
  CreateRoomTypes,
  InvitationActionTypes,
  RoomChatTypes,
  RoomPlaybackControlsTypes,
  SessionEventTypes
}
