import { generateGetters, generateMutations } from '../helpers'
const state = {
  advancedOpts: {
    web: {
      showInfo: true,
      showInput: false
    },
    p2p: {
      noSTUN: false,
      noTURN: false
    }
  }
}

const getters = generateGetters(state)

const mutations = generateMutations(state)

export default {
  state,
  getters,
  mutations
}
