function generateGetters (state) {
  const getters = {}
  Object.keys(state).forEach(key => {
    getters[key] = state => state[key]
  })
  return getters
}

function generateMutations (state) {
  const mutations = {}
  Object.keys(state).forEach(key => {
    mutations[key] = (state, v) => { state[key] = v }
  })
  return mutations
}

function illegalMutation () {
  throw new Error('Illegal mutation')
}

function mapGettersAndSetters (properties) {
  const result = {}
  for (const property of properties) {
    result[property] = {
      get () {
        return this.$store.getters[property]
      },
      set (v) {
        this.$store.commit(property, v)
      }
    }
  }
  return result
}

export {
  generateGetters,
  generateMutations,
  illegalMutation,
  mapGettersAndSetters
}
