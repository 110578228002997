import { generateGetters, generateMutations } from '../helpers.js'

const state = {
  groupChatMainWebcam: undefined
}

const getters = {
  ...generateGetters(state)
}

const mutations = {
  ...generateMutations(state)
}

export default {
  state,
  getters,
  mutations
}
