/* global RESOURCES_SERVER_ORIGIN */
import AppBus from '@C/bus/app-bus.vue'
import { RoomTypes } from '@root/backend/databases/events.js'
// import { MediaEntry } from '@root/backend/databases/media-store.js'
import cloneDeep from 'lodash/cloneDeep.js'

import { privileges } from '@root/backend/patron-privileges/privileges.js'
import { isFirefox } from '@/js/is.js'
import { generateGetters, generateMutations, illegalMutation } from '../helpers.js'
import { needsExtensionUpdate } from '@/js/utils.js'
import Deferred from '@/js/deferred.js'
import { apiRequest } from '@/js/api-request.js'
import { useStorage } from '@vueuse/core'
import groupChat from './group-chat.js'

const maxGroupChatVideos = 20

const defaultVideoSelectors = {
  youtube: {
    displayName: 'YouTube',
    type: 'youtube',
    mediaEntry: {},
    exportToWindow: true,
    selectorWarning: {
      label: 'youtube-adblock',
      message: 'Playback error? Logout of YouTube and refresh this page.',
      check (videoElement) {
        return true
      }
    },
    plyrOpts: {
      youtube: {
        noCookie: false
      }
    }
  },
  netflix: {
    displayName: 'Netflix',
    type: 'iframe',
    mediaEntry: {},
    defaultURL: '//www.netflix.com/browse',
    sameSiteDomains: ['netflix.com'],
    minExtensionVersion: '2.2.65',
    exportToWindow: true,
    notifyExtension: true,
    selectorWarning: {
      label: 'red-N',
      message: 'Stuck on the logo? Right-click the logo, and click "Reload Frame".',
      check (videoElement) {
        return true
      }
    }
  },
  hulu: {
    displayName: 'Hulu',
    type: 'iframe',
    mediaEntry: {},
    defaultURL: '//www.hulu.com',
    sameSiteDomains: ['hulu.com'],
    exportToWindow: true,
    notifyExtension: true,
    minExtensionVersion: '2.2.65',
    isThirdParty: true,
    selectorWarning: {
      label: 'chrome-extn-update',
      message: 'Having issues? Go to chrome://extensions and toggle the twoseven extension off and back on',
      async check (videoElement) {
        return !isFirefox()
      }
    },
    needsPrivilege: true,
    hasPrivilege (app, state) {
      let roomProperties = {}
      if (app) {
        const { $store: { getters: { roomProperties: rp } } } = app
        roomProperties = rp
      } else if (state) {
        roomProperties = state.roomProperties
      }
      const { privileges: roomPrivileges = {} } = roomProperties
      const { [privileges.VIDEO_SELECTOR_HULU]: hasHuluPrivilege } = roomPrivileges
      return hasHuluPrivilege
    }
  },
  disneyplus: {
    displayName: 'Disney+',
    type: 'iframe',
    mediaEntry: {},
    defaultURL: '//www.disneyplus.com',
    sameSiteDomains: ['disneyplus.com'],
    exportToWindow: true,
    notifyExtension: true,
    minExtensionVersion: '2.2.65',
    isThirdParty: true,
    selectorWarning: {
      label: 'chrome-extn-update',
      message: 'Please use Firefox until Chrome extension is updated to v2.2.15',
      async check (videoElement) {
        const needsUpdate = needsExtensionUpdate('2.2.15', videoElement.$store.getters.extensionVersion)
        return !isFirefox() && needsUpdate
      }
    },
    needsPrivilege: true,
    hasPrivilege (app, state) {
      let roomProperties = {}
      if (app) {
        const { $store: { getters: { roomProperties: rp } } } = app
        roomProperties = rp
      } else if (state) {
        roomProperties = state.roomProperties
      }
      const { privileges: roomPrivileges = {} } = roomProperties
      const { [privileges['VIDEO_SELECTOR_DISNEY+']]: hasDisneyPlusPrivilege } = roomPrivileges
      return hasDisneyPlusPrivilege
    }
  },
  amazon: {
    displayName: 'Amazon',
    type: 'iframe',
    defaultURL: '//www.amazon.com/Amazon-Video/b?ie=UTF8&node=2858778011',
    sameSiteDomains: ['amazon.com'],
    // defaultURL: 'https://www.amazon.com/gp/video/detail/B00IA56DR6/?autoplay=1',
    mediaEntry: {},
    attrs: {
      websites: {
        amazon: {
          displayName: 'amazon.com',
          url: '//www.amazon.com/Amazon-Video/b?ie=UTF8&node=2858778011'
        },
        primevideo: {
          displayName: 'primevideo.com',
          url: '//www.primevideo.com'
        }
      }
    },
    exportToWindow: true,
    notifyExtension: true,
    isThirdParty: true,
    minExtensionVersion: '2.2.65'
  },
  hbo: {
    displayName: 'HBO',
    type: 'iframe',
    sameSiteDomains: ['max.com'],
    defaultURL: '//play.max.com',
    mediaEntry: {},
    exportToWindow: true,
    notifyExtension: true,
    minExtensionVersion: '2.2.68',
    isThirdParty: true
  },
  // peacock: {
  //   displayName: 'Peacock',
  //   type: 'iframe',
  //   defaultURL: '//www.peacocktv.com/watch/home',
  //   sameSiteDomains: ['peacocktv.com', 'www.peacocktv.com'],
  //   mediaEntry: {},
  //   exportToWindow: true,
  //   notifyExtension: true,
  //   minExtensionVersion: '2.2.81',
  //   isThirdParty: true,
  //   hide: true
  // },
  appletv: {
    displayName: 'Apple TV+',
    type: 'iframe',
    defaultURL: '//tv.apple.com/',
    sameSiteDomains: ['tv.apple.com', 'mediaauth.apple.com', 'idmsa.apple.com'],
    mediaEntry: {},
    exportToWindow: true,
    notifyExtension: true,
    minExtensionVersion: '2.2.81',
    isThirdParty: true
  },
  paramountplus: {
    displayName: 'Paramount Plus',
    type: 'iframe',
    defaultURL: '//www.paramountplus.com/home/',
    sameSiteDomains: ['paramountplus.com', 'www.paramountplus.com'],
    mediaEntry: {},
    exportToWindow: true,
    notifyExtension: true,
    minExtensionVersion: '2.2.81',
    isThirdParty: true,
    hide: true
  },
  vimeo: {
    displayName: 'Vimeo',
    type: 'vimeo',
    mediaEntry: {},
    exportToWindow: true
  },
  browse: {
    displayName: 'Browse',
    type: 'iframe',
    mediaEntry: {},
    attrs: {
      showInput: true,
      websites: {
        einthusan: {
          displayName: 'Einthusan',
          url: 'https://einthusan.tv'
        }
        // viooz: {
        //   displayName: 'Viooz',
        //   url: 'https://viooz.ac'
        // }
      }
    },
    exportToWindow: true,
    notifyExtension: true,
    hide: true,
    isThirdParty: true
  },
  local: {
    displayName: 'Personal',
    type: 'local',
    mediaEntry: {},
    hide: false,
    exportToWindow: true,
    plyrOpts: {
      captions: {
        upload: {
          formats: ['srt', 'vtt', 'ssa', 'ass'],
          enabled: true,
          callback: true
        }
      }
    }
  },
  web: {
    displayName: 'Web',
    type: 'web',
    mediaEntry: {},
    exportToWindow: true,
    hide: false,
    isThirdParty: true,
    plyrOpts: {
      captions: {
        upload: {
          formats: ['srt', 'vtt', 'ssa', 'ass'],
          enabled: true,
          callback: true
        }
      }
    }
  },
  screenshare: {
    displayName: 'Share Screen',
    type: 'screenshare',
    mediaEntry: {},
    exportToWindow: true,
    minExtensionVersion: '2.2.65',
    needsPrivilege: false,
    hasPrivilege (app, state) {
      let roomProperties = {}
      if (app) {
        const { $store: { getters: { roomProperties: rp } } } = app
        roomProperties = rp
      } else if (state) {
        roomProperties = state.roomProperties
      }
      const { privileges: roomPrivileges = {} } = roomProperties
      const { [privileges.VIDEO_SELECTOR_SCREENSHARE]: hasScreensharePrivilege } = roomPrivileges
      return hasScreensharePrivilege
    }
  },
  headspace: {
    displayName: 'Headspace',
    type: 'iframe',
    name: 'headspace-iframe',
    mediaEntry: {},
    exportToWindow: true,
    notifyExtension: true,
    hide: true,
    minExtensionVersion: '2.2.65',
    isThirdParty: true
  },
  webpage: {
    displayName: 'Web Page',
    type: 'iframe',
    name: 'twoseven-watch-iframe',
    mediaEntry: {},
    exportToWindow: true,
    notifyExtension: true,
    hide: true,
    isThirdParty: true
  },
  crunchyroll: {
    displayName: 'Crunchyroll',
    type: 'iframe',
    defaultURL: '//www.crunchyroll.com',
    sameSiteDomains: ['crunchyroll.com', 'static.crunchyroll.com'],
    mediaEntry: {},
    exportToWindow: true,
    notifyExtension: true,
    minExtensionVersion: '2.2.75',
    isThirdParty: true,
    hide: true
  },
  viki: {
    displayName: 'Rakuten Viki',
    type: 'iframe',
    defaultURL: '//www.viki.com',
    sameSiteDomains: ['viki.com', 'www.viki.com'],
    mediaEntry: {},
    exportToWindow: true,
    notifyExtension: true,
    minExtensionVersion: '2.2.81',
    isThirdParty: true,
    hide: true
  },
  jiocinema: {
    displayName: 'JioCinema',
    type: 'iframe',
    defaultURL: '//www.jiocinema.com',
    mediaEntry: {},
    exportToWindow: true,
    notifyExtension: true,
    minExtensionVersion: '2.2.79',
    isThirdParty: true,
    hide: true
  },
  groupchat: {
    displayName: 'Group Chat',
    type: 'group-chat',
    mediaEntry: {},
    props: {
      maxVideos: maxGroupChatVideos // XXX: Not sure why this can't be 20, but it only shows maxVideos - 1 for some reason
    },
    hide: false,
    exportToWindow: true
  }
}

const Strings = Object.freeze({
  PRIVILEGED_FEATURE: 'This is a supporter-only feature.'
})

function initialState () {
  const screenshareResolutions = [
    {
      value: {
        width: 1280,
        height: 720
      },
      label: '720p'
    },
    {
      value: {
        width: 1920,
        height: 1080
      },
      label: '1080p',
      unlocked (app) {
        return app.$store.getters.userProfile?.privileges?.[privileges.SCREENSHARE_RESOLUTION_1080P]
      },
      icon: 'lock'
    }
  ]
  const screenshareSettings = useStorage('screenshareSettings', { resolution: screenshareResolutions[0].value, codec: 'video/vp8' }, localStorage)
  // TODO: Remove this after 2022
  if (screenshareSettings.value.resolution && screenshareSettings.value.resolution.value) {
    screenshareSettings.value.resolution = screenshareResolutions[0].value
  }
  if (!screenshareSettings.value.codec) {
    screenshareSettings.value.codec = 'video/vp8'
  }

  const selfVideoInGrid = useStorage('selfVideoInGrid', false, localStorage)

  return {
    isRoom: false,
    roomReady: new Deferred(),
    roomName: undefined,
    encryptedRoomID: undefined,
    roomType: undefined,
    roomOwner: undefined,
    roomController: undefined,
    roomUsers: {},
    activeRoomUsers: {},
    bannedUsers: [],
    theaterMode: false,
    roomProperties: {
      p2p: true,
      privileges: {}
    },
    roomMetadata: {},
    userJoinLeaveToastNotification: true,
    userJoinLeaveAudioNotification: false,
    showVideoTitleInTheaterMode: false,
    showSyncVideoTip: true,
    activeTab: undefined,
    currentSelector: undefined,
    lastPlayedVideoSelector: undefined,
    localLastPlayedVideoSelector: undefined, // Used to track the last played video selector locally. This is used during playlist logic when it switches to the next video
    videoSelectors: cloneDeep(defaultVideoSelectors),
    isFullscreen: undefined,
    mediaModified: false,
    tabs: [
      {
        label: 'Webcam',
        id: 'webcam-tab-option',
        selector: '#webcam-row',
        originalParent: '#webcam-row-container',
        destination: '#webcam-tab'
      },
      {
        label: 'Chat',
        id: 'chat-tab-option',
        bindClass: function (component, store) {
          return [store.getters.activeTab !== component.data && store.getters.unreadMessages.length > 0 ? 'unread-message' : '']
        },
        selector: '#discussion-div',
        originalParent: '#chat-section',
        destination: '#chat-tab',
        onSelect: (dispatch, state, commit) => {
          dispatch('clearUnreadMessages')
        },
        onResize: (store, newScreenType) => {
          if (store.getters.activeTab !== this) {
            store.commit('showingDiscussion', false)
          }
        }
      },
      // The position of search results is expected to be 2 in processInput()
      {
        label: 'Search Results',
        id: 'search-results-option',
        selector: '#search-results',
        originalParent: '#search-results-container',
        destination: '#search-results-tab',
        mounted: function (component, store) {
          AppBus.$on('search-started', () => {
            store.dispatch('setActiveTab', component.data)
          })
        }
      }
    ],
    screenshareResolutions,
    screenshareCodecs: [
      { value: 'video/h264', label: 'H.264', description: 'Use this if participants may be using older devices' },
      { value: 'video/vp8', label: 'VP8', description: 'Newer codec, better screenshare quality' }
    ],
    screenshareSettings,
    playlistData: {},
    playlistOrder: [],
    playlistDD: false,
    maxGroupChatVideos,
    selfVideoInGrid
  }
}

const state = initialState()

const getters = {
  ...generateGetters(state),
  Strings: () => Strings,
  syncVideoTip: () => 'Perform a Play/Pause/Seek action to begin sync',
  isModerator: state => {
    const { roomOwner, roomController } = state
    return roomOwner || roomController
  },
  canUpdateMedia: state => {
    const { roomType, roomOwner, roomController } = state
    if (roomType === RoomTypes.DICTATOR && !roomOwner && !roomController) {
      return false
    }
    return true
  },
  screenshareSettings: state => {
    // We're having to do this ugly if-else because it is not clear what vueuse/core returns
    if (state.screenshareSettings.value) {
      return state.screenshareSettings.value
    } else {
      return state.screenshareSettings
    }
  }
}

const mutations = {
  ...generateMutations(state),
  currentSelector (state, v) {
    try {
      const selectorData = state.videoSelectors[v]
      const { needsPrivilege = true, hasPrivilege = true } = selectorData
      if (needsPrivilege) {
        if (!hasPrivilege) {
          return
        }
        if (typeof hasPrivilege === 'function' && !hasPrivilege(null, state)) {
          return
        }
      }
    } catch (e) {
    }
    state.currentSelector = v
  },
  roomUsers: illegalMutation,
  theaterMode: illegalMutation,
  _theaterMode (state, v) {
    state.theaterMode = v
  },
  roomProperties: illegalMutation,
  isFullscreen (state, val) {
    state.isFullscreen = !!val
  },
  mediaModified (state, val) {
    state.mediaModified = !!val
  },
  tabs: illegalMutation,
  screenshareSettings (state, v) {
    const { state: { userProfile } } = this

    const checkAndUpdate = () => {
      const { resolution: { height } } = v
      if (height === 1080) {
        const { privileges: { [privileges.SCREENSHARE_RESOLUTION_1080P]: has1080pPrivilege } } = userProfile
        if (has1080pPrivilege) {
          // Do nothing. We're allowed to use it
        } else {
          v.resolution = state.screenshareResolutions[0]
        }
      }
      state.screenshareSettings = v
    }

    if (!userProfile) {
      // Wait for userProfile to be initialized and then run this
      const unwatch = this.watch(() => !!this.state.userProfile, () => {
        // We have a userProfile. It should take the other path now
        unwatch()
        checkAndUpdate()
      })
      return
    }
    checkAndUpdate()
  }
}

const actions = {
  setActiveTab ({ dispatch, state, commit }, tab) {
    commit('activeTab', tab)
    if (tab.onSelect) {
      tab.onSelect(dispatch, state, commit)
    }
  },
  resetRoom ({ state }) {
    const s = initialState()
    Object.entries(s).forEach(([k, v]) => {
      state[k] = v
    })
  },
  resetVideoSelectors ({ state, commit }) {
    commit('videoSelectors', cloneDeep(defaultVideoSelectors))
  },
  toggleTheaterMode ({ state, commit }, v) {
    commit('_theaterMode', v)
    if (v) {
      // rootState.textChat.showingDiscussion = true
      commit('showingDiscussion', true)
    }
  },
  async processCaptions ({ state }, captions) {
    const { encryptedRoomID } = state
    const result = await Promise.all(captions.map(async entry => {
      let { text, vtt, ...props } = entry
      text = text || vtt // TODO: Remove this code after 2022-04-01
      if (!text) {
        // We need to try and fetch the src
        try {
          const response = await fetch(entry.src)
          text = await response.text()
        } catch (e) {
          return null
        }
      }
      // TODO: Deal with failure scenario
      const response = await apiRequest(`${RESOURCES_SERVER_ORIGIN}/api/resource/${encryptedRoomID}/`, { body: text }, 'post')
      const src = `${RESOURCES_SERVER_ORIGIN}${response.data}`
      return { ...props, length: text.length, src }
    }))
    return result.filter(x => !!x)
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  modules: {
    groupChat
  }
}
