import Deferred from '@/js/deferred.js'
import compareVersions from 'compare-versions'

export function log (msg) {
}

export function isTouchScreen () {
  try {
    document.createEvent('TouchEvent')
    return true
  } catch (e) {
    return false
  }
}

export function getQueryString (string) {
  const url = new URL(string)
  const { searchParams } = url
  const result = {}
  for (const [k, v] of searchParams.entries()) {
    result[k] = v
  }
  return result
}

export function basename (path) {
  return path.replace(/.*\//, '')
}

export function dirname (path) {
  return path.match(/.*\//)
}

export function stripExtension (input) {
  console.log('Attempting filename parsing :' + input)
  const bname = this.basename(input)
  const dname = this.dirname(input)
  console.log('dirname:' + dname)
  const fname = bname.replace(/\.[^/.]+$/, '')
  console.log('fname:' + fname)
  return dname + fname
}

/* Forked from https://gist.github.com/takien/4077195 */
export function parseYouTubeVideoID (url) {
  let videoId
  url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
  if (url[2] !== undefined) {
    videoId = url[2].split(/[^0-9a-z_-]/i)
    videoId = videoId[0]
  } else {
    // TODO: Add support for directly entering videoId
    // videoId = url
  }
  return videoId
}

export function parseVimeoVideoID (url) {
  if (!url) {
    return
  }
  // var regex = /^.*(vimeo.com\/|video\/)(\d+).*/
  const regex = '(https?://)?(www.)?(player.)?vimeo.com/([a-z]*/)*([0-9]{6,11})[?]?.*'
  const result = (url.match(regex)) ? RegExp.$5 : undefined
  if (result) {
    return result
  }
  // This wasn't a full URL. Check to see if this is a videoId
  // Vimeo videoIds are all numbers, so just try to convert to a number
  if (!isNaN(url)) {
    // This is indeed a videoId
    return url
  }
  return undefined
}

export function toast (msg, delay, cls) {
  delay = delay || 5000
  cls = cls || 'client-toast'
  const $toastContent = `<span> ${msg} </span> <a class="toast-close-a"><i class="material-icons toast-close right toast-action">close</i></a>`
  const toast = window.M.toast({
    html: $toastContent,
    displayLength: delay,
    classes: cls
  })
  Array.from(toast.el.querySelectorAll('.toast-close')).forEach(el => el.addEventListener('click', () => {
    toast.dismiss()
  }))
}

export async function toasted (msg, opts, app = window.app) {
  const { default: Vue } = await import('vue')
  const { default: Toasted } = await import('vue-toasted')
  Vue.use(Toasted)
  const isLargeScreen = app ? app.$store.getters.screenType === 'large' : true
  const finalOpts = {
    position: isLargeScreen ? 'bottom-right' : 'bottom-center',
    fullWidth: !isLargeScreen,
    fitToScreen: !isLargeScreen,
    className: 'client-toast',
    duration: 5000
  }
  Object.assign(finalOpts, opts)
  return Vue.toasted.show(msg, finalOpts)
}

export function getGravatar (userHash, size) {
  size = size || 128
  return `/api/gravatar/${userHash}/${size}`
}

export async function convertAESToSHA (emailHash) {
  const r = await fetch(`/api/aes-to-sha/${emailHash}`)
  return r.text()
}

export const xhr = {
  async testGetUrl (url) {
    const { default: axios } = await import('axios')
    try {
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()
      const response = await axios.get(url, {
        cancelToken: source.token,
        onDownloadProgress (evt) {
          if (evt.currentTarget.status !== 200) {
            return source.cancel('failed')
          }
          return source.cancel('working')
        }
      })
      if (response.status !== 200) {
        throw new Error(`GET request failed: ${response.data}`)
      }
    } catch (e) {
      if (!axios.isCancel(e) || e.message === 'failed') {
        throw new Error(`GET request failed: ${e.message}`)
      }
    }
  },
  async getResponseHeader (url, header) {
    const response = await fetch(url, { method: 'HEAD' })
    return response.headers.get('content-type')
  }
}

export function triggerEvent (el, name, data) {
  const evt = new CustomEvent(name, { detail: { data: data } })
  el.dispatchEvent(evt)
}

export function getRTCConfig (socket) {
  return new Promise((resolve, reject) => {
    socket.emit('get-rtc-config', (config) => {
      console.log('Got RTC config from server')
      resolve(config)
    })
  })
}

export async function waitForDOMNode (identifier, observeNode = document) { // eslint-disable-line no-unused-vars
  identifier = identifier || {}
  const { type, value: rawValue } = identifier
  if (!type || !rawValue) {
    throw new Error('Must specify identifier')
  }

  // If type tag, ensure value is uppercase
  let value = rawValue
  switch (type) {
    case 'tag':
      value = rawValue.toUpperCase()
      break
  }

  if (type !== 'check') {
    const el = await findEl({ type: 'selector', value }, [], observeNode)
    if (el) {
      return new Deferred(resolve => resolve(el))
    }
  }

  async function findEl ({ type, value }, elements, observeNode) {
    let el
    switch (type) {
      case 'tag':
        el = elements.find(node => node.tagName === value)
        break
      case 'selector':
        el = observeNode.querySelector(value)
        break
      case 'check': {
        for (const el of elements) {
          const result = await value(el)
          if (result) {
            return el
          }
        }
        break
      }
    }
    return el
  }

  const promise = new Deferred((resolve, reject) => {
    const observer = new MutationObserver(async mutations => {
      for (const mutation of mutations) {
        if (mutation.type === 'childList') {
          const added = Array.from(mutation.addedNodes)
          const el = await findEl({ type, value }, added, observeNode)
          if (el) {
            observer.disconnect()
            return resolve(el)
          }
        }
      }
    })
    observer.observe(observeNode, {
      childList: true,
      subtree: true
    })
  })
  return promise
}

export function needsExtensionUpdate (minExtensionVersion = '0.0.0', extensionVersion = '0.0.0') {
  if (!extensionVersion) {
    return true
  }
  if (extensionVersion === true) {
    extensionVersion = '0.0.0'
  }
  if (minExtensionVersion !== extensionVersion) {
    return compareVersions(extensionVersion, minExtensionVersion) === -1
  }
  return false
}

export function diffStreams (v, o) {
  let oldStreamTracks = []
  let newStreamTracks = []
  if (o) {
    oldStreamTracks = o.getTracks()
  }
  if (v) {
    newStreamTracks = v.getTracks()
  }
  const newTracks = newStreamTracks.filter(x => !oldStreamTracks.includes(x))
  return newTracks
}

const trackEndedSet = new WeakSet()
export function setupTrackEnded (tracks, cb) {
  if (!Array.isArray(tracks)) {
    tracks = [tracks]
  }
  // Set up a listener for each of track to know when they end so we can signal the end
  // to any interested clients
  for (const track of tracks) {
    if (trackEndedSet.has(track)) {
      return
    }
    track.onended = () => {
      cb(track)
    }
    trackEndedSet.add(track)
  }
}

const wrappedTracks = new WeakSet()
export function wrapCloneTrack (streamOrTrack) {
  let tracks
  if (streamOrTrack instanceof MediaStream) {
    tracks = streamOrTrack.getTracks()
  } else if (streamOrTrack instanceof MediaStreamTrack) {
    tracks = [streamOrTrack]
  } else {
    throw new Error('Input argument of unknown type. Expected MediaStream or MediaStreamTrack')
  }
  tracks.forEach(t => {
    if (wrappedTracks.has(t)) {
      return
    }
    const { clone } = t
    t.clone = () => {
      const ret = clone.bind(t)()
      t.addEventListener('ended', () => {
        ret.stop()
      })
      return ret
    }
    wrappedTracks.add(t)
  })
}

export function readBytesFromFile (file, numBytes) {
  if (numBytes <= 0) {
    numBytes = file.length
  } else if (numBytes > file.length) {
    numBytes = file.length
  }
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = async (e) => {
      const { result } = reader
      resolve(result.slice(0, numBytes))
    }
    reader.readAsArrayBuffer(file)
  })
}

export async function getVideoFileInfo (file, app) {
  const { name } = file
  // Get first 64K of file and pass it to backend to detect whether video/audio encoding is supported
  const bytes = await readBytesFromFile(file, 1024 * 1024) // eslint-disable-line
  // Make an API request to the backend asking if this file is supported.
  // This is good-to-have and not necessary. So catch all exceptions and proceed anyway
  const body = new FormData()
  body.append('name', name)
  body.append('file', new Blob([bytes]))
  const response = await app.apiRequest('/api/ffprobe/check', {
    body,
    headers: {
      'content-type': 'multipart/form-data'
    }
  }, 'post')
  return response.data
}

export async function timeRestrictedPromise (fn, timeoutMS = 250) {
  const result = await new Promise((resolve, reject) => {
    const failed = false // FIXME: Something's not quite right here. This can't be a const
    const now = Date.now()
    const timeout = setTimeout(() => {
      reject(new Error(`Timed out in ${timeoutMS}ms`))
    }, timeoutMS)
    fn().then(results => {
      if (failed) {
        console.warn(`Timed out call completed in: ${Date.now() - now}ms`)
      } else {
        clearTimeout(timeout)
        resolve(results)
      }
    }).catch(err => {
      if (failed) {
        console.warn(`Promise errored out in ${Date.now() - now}ms: ${err}`)
        return
      }
      clearTimeout(timeout)
      reject(err)
    })
  })
  return result
}

export function isElementInView (el, container) {
  const { bottom, height, top } = el.getBoundingClientRect()
  const containerRect = container.getBoundingClientRect()

  return top <= containerRect.top
    ? (containerRect.top - top <= height)
    : bottom < containerRect.bottom
}

export async function recodeViaBackend (text, track) {
  const { format, src } = track
  const data = new FormData()

  let filename = new URL(src).pathname.split('/').slice(-1)[0]
  if (format && !filename.endsWith(format)) {
    filename = `${filename}.${format}`
  }
  data.append('name', filename)
  const file = new File([new Blob([text])], filename)
  data.append('file', file)
  const response = await global.app.apiRequest('/api/captions/recode', {
    body: data,
    headers: {
      'content-type': 'multipart/form-data'
    }
  }, 'post')
  return response.data.text
}

export async function recodeText (text) {
  const { default: iconv } = await import('iconv-lite')
  const { default: chardet } = await import('chardet')

  const buffer = new TextEncoder().encode(text)
  const encoding = chardet.detect(buffer)
  const result = iconv.encode(iconv.decode(buffer, encoding), 'utf8').toString('utf8')
  return result
}

export async function recodeTrack (track) {
  let { format, text, src } = track
  if (format === 'vtt') {
    return track
  }
  if (!text) {
    if (!src) {
      throw new Error('No \'text\' field and no \'src\' field')
    }
    try {
      const response = await fetch(src)
      text = await response.text()
    } catch (e) {
      throw new Error('No \'text\' field and unable to fetch \'src\'')
    }
  }
  text = await recodeText(text)

  const { default: subsrt } = await import(/* webpackChunkName: "subsrt" */ '@gurupras/subsrt/lib/subsrt.js')
  if (format !== 'vtt') {
    const captions = subsrt.parse(text)
    text = subsrt.build(captions, { format: 'vtt' })
  }
  track.text = text
  return track
}
