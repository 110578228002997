/* global LOADBALANCER_URL, ROOM_LIFE_CYCLE, ROOM_DEFAULT_REACTION_URLS, SOCKETIO_USE_MSGPACK */
import Vue from 'vue'
import Vuex from 'vuex'
import deepmerge from 'deepmerge'
import customParser from 'socket.io-msgpack-parser'
import links from './modules/links.js'
import is from './modules/is.js'
import home from './modules/home.js'
import room from './modules/room.js'
import textChat from './modules/text-chat.js'
import videoSearch from './modules/video-search.js'
import simplePeer from './modules/simple-peer.js'
import user from './modules/user.js'
import social from './modules/social.js'
import localVideo from './modules/local-video.js'
import advancedOptions from './modules/advanced-options.js'
import { generateGetters, generateMutations, illegalMutation } from './helpers.js'

import AppBus from '@C/bus/app-bus.vue'

import { toast, triggerEvent, getGravatar } from '@/js/utils.js'
import Deferred from '@/js/deferred.js'
import APIRequest from '@/js/api-request.js'
import Analytics from '@/js/analytics/index.js'
import { isIOS, isMobile, isTablet } from '@/js/is.js'
import Themes from '@/style/themes.module.scss'
import { RoomTypes, RoomEventTypes } from '@root/backend/databases/events.js'
import FusionAuthConfig from '@root/static/scripts/fusionauth-config.js'

const { apiRequest, getInstance: getAPIRequestInstance } = APIRequest
const { opts: { storage } } = FusionAuthConfig

Vue.use(Vuex)

const defaultReactionUrls = ROOM_DEFAULT_REACTION_URLS

const state = {
  appStatus: [],
  showNavbar: true,
  sessionID: undefined,
  theme: undefined,
  defaultRoomTypeIsDictator: false,
  defaultRoomHasP2P: true,
  firstInteractionOccurred: false,
  screenType: 'unknown',
  showSidenav: true,
  sidenavOpen: false,
  authLock: undefined,
  authState: undefined,
  socket: undefined,
  userProfile: undefined,
  accessToken: undefined,
  tokenRefreshTimeout: undefined,
  isLoggedIn: false,
  isAdmin: false,
  extensionVersion: '0.0.0',
  extensionVersionPromise: new Deferred(),
  extensionNetworkRequestAction: undefined,
  onSocketAvailable: undefined,
  videoSettings: {
    resetPlyrSpeed: false,
    plyr__captions: {
      selector: '.plyr__captions',
      bottomCaptions: {
        value: true,
        css: function () {
          return [{
            key: this.value ? 'bottom' : 'top',
            value: '0 !important'
          }]
        }
      },
      fontSize: {
        value: 18,
        css: function () {
          return [{
            key: 'font-size',
            value: `${this.value}px !important`
          }]
        }
      },
      opacity: {
        value: 80,
        selector: '.plyr__captions > .plyr__caption',
        css: function () {
          return [{
            key: 'background',
            value: `rgba(0, 0, 0, ${this.value / 100.0})`
          }]
        }
      }
    }
  },
  userStylesheet: {
    chatGlowColor: '#d35400'
  },
  showFooter: true,
  reactionsEnabled: true,
  reactionUrls: [
    ...defaultReactionUrls
  ],
  defaultReactionUrls: [...defaultReactionUrls],
  showReactionsOverlay: true,
  geoData: undefined,
  idleCheck: {
    interval: undefined,
    timeout: undefined,
    expiresAt: undefined,
    now: undefined
  },
  extensionConfig: {
    settings: {
      keys: {},
      data: {}
    },
    clientID: undefined
  },
  showRoomPropertiesOnRoomCreate: true
}

const getters = {
  ...generateGetters(state),
  nickname: state => {
    const userProfile = state.userProfile
    if (userProfile) {
      return userProfile.nickname
    }
    return ''
  },
  userIdentifier: state => {
    const { sessionID, userProfile } = state
    if (!userProfile) {
      return null
    }
    const { userHash } = userProfile
    return `${sessionID}:${userHash}`
  },
  canUseApp: state => {
    return !isIOS()
  },
  markdownAttributes: state => {
    return {
      emoji: true,
      simplifiedAutoLink: true,
      simpleLineBreaks: true,
      openLinksInNewWindow: true
    }
  },
  zeroMarginOnTouch: state => {
    if (state.screenType === 'med-and-down') {
      return 'is-marginless'
    }
    return ''
  },
  zeroPaddingOnTouch: state => {
    if (state.screenType === 'med-and-down') {
      return 'is-paddingless'
    }
    return ''
  },
  defaultRoomProperties: state => {
    return {
      p2p: state.defaultRoomHasP2P,
      roomType: state.defaultRoomTypeIsDictator ? RoomTypes.DICTATOR : RoomTypes.DEMOCRATIC
    }
  },
  fusionauth: state => {
    return FusionAuthConfig
  },
  roomLifeCycle: () => ROOM_LIFE_CYCLE,
  hasExtension: (state) => {
    return state.extensionVersion !== '0.0.0'
  }
}

const mutations = {
  ...generateMutations(state),
  accessToken: illegalMutation,
  videoSettings (state, videoSettings) {
    state.videoSettings = deepmerge(state.videoSettings, videoSettings)
  },
  userStylesheet (state, userStylesheet) {
    state.userStylesheet = deepmerge(state.userStylesheet, userStylesheet)
  },
  showFooter (state, val) {
    const { userProfile } = state
    if (val) {
      state.showFooter = val
      return
    }
    if (userProfile) {
      if (userProfile.isPatron) {
        state.showFooter = val
      }
    } else {
      // Wait for userProfile to be initialized and then run this
      const unwatch = this.watch(state => !!state.userProfile, () => {
        // We have a userProfile. It should take the other path now
        unwatch()
        this.commit('showFooter', val)
      })
    }
  },
  firstInteractionOccurred (state, v) {
    state.firstInteractionOccurred = v
    console.log('First interaction occurred')
  }
}

let initializePromise

async function getAppServerAddressFromLoadBalancer ({ roomName }, retryCount = 5, retryIntervalMillis = 1000) {
  for (let idx = 0; idx < retryCount; idx++) {
    try {
      const url = new URL(LOADBALANCER_URL)
      const { searchParams } = url
      if (roomName) {
        searchParams.set('roomName', roomName)
      }
      const forceServers = JSON.parse(sessionStorage.getItem('lb-force-servers') || null)
      if (forceServers) {
        searchParams.set('forceServers', forceServers)
      }
      url.search = searchParams.toString()
      const loadBalancerURL = url.toString()
      const response = await apiRequest(loadBalancerURL)
      const { data: { address } } = response
      if (address) {
        return address
      }
    } catch (e) {
      console.error(e)
    }
    await new Promise(resolve => setTimeout(resolve, retryIntervalMillis))
  }
  throw new Error('Failed to get app-server address')
}

const actions = {
  async updateAccessToken ({ state, commit, dispatch }, token) {
    state.accessToken = token
    const instance = await getAPIRequestInstance()
    instance.defaults.headers.Authorization = `Bearer ${token}`
    if (!token) {
      return
    }
    const doRefresh = async () => {
      console.log('Refreshing tokens to avoid expiration')
      await dispatch('refreshAccessToken')
    }

    try {
      // Set up timer to refresh token
      const { default: jwtDecode } = await import(/* webpackChunkName: "jwt-decode" */ 'jwt-decode')
      const decoded = jwtDecode(token)
      const { exp } = decoded
      // FIXME: This might need special handling if accessTokens are made to last longer than 2^31-1 milliseconds
      const expiryMillis = exp * 1000
      const bufferPeriod = 5 * 60 * 1000 // 5 minute buffer
      // It is possible that the token expires within bufferPeriod. If that is the case, then run immediately
      const timeoutMillis = Math.max(0, (expiryMillis - Date.now() - bufferPeriod))
      state.tokenRefreshTimeout && clearTimeout(state.tokenRefreshTimeout)
      if (timeoutMillis === 0) {
        await doRefresh()
      } else {
        state.tokenRefreshTimeout = setTimeout(doRefresh, timeoutMillis)
      }
    } catch (err) {
      console.error(err && err.stack)
      // dispatch('invalidateAccessToken')
    }
  },
  async refreshAccessToken ({ state, dispatch }) {
    const { authLock, socket } = state
    const accessToken = await authLock.refreshTokens()
    dispatch('updateAccessToken', accessToken)
    storage.setItem('auth-accessToken', accessToken)
    if (socket) {
      socket.emit('refresh-token', accessToken)
    }
  },
  async initialize ({ dispatch, commit, state }) {
    const work = async () => {
      commit('onSocketAvailable', new Deferred())
      await dispatch('initAuth')
      await dispatch('updateAccessToken', storage.getItem('auth-accessToken'))
      try {
        await dispatch('getAuthProfile')
      } catch (e) {
      }
      AppBus.$emit('app-ready')
    }
    initializePromise = initializePromise || new Promise((resolve) => {
      work().then(() => resolve())
    })
    return initializePromise
  },

  async updateSocket ({ commit, state }, socket) {
    if (state.socket) {
      state.socket.disconnect()
    }
    commit('socket', socket)
    state.onSocketAvailable.resolve(socket)
  },

  async initializeSocketIo ({ dispatch, commit, state }, opts = {}) {
    let { payload, replaceSocket = true } = opts
    if (state.socket && replaceSocket) {
      console.warn('Existing socket being disconnected')
      state.socket.disconnect()
      commit('socket', undefined)
    }

    const socketioPromise = new Deferred()
    Promise.resolve().then(async () => {
      const { accessToken: token, sessionID } = state
      if (!payload) {
        payload = {}
      }
      const queryParams = payload.queryParams
      let url = payload.url

      const qp = {
        sessionID,
        token
      }
      Object.assign(qp, queryParams)
      // First, get the app server's address from the loadbalancer
      // TODO: getAppServerAddressFromLoadBalancer can throw if it gets no address. Handle this
      const address = await getAppServerAddressFromLoadBalancer(qp)
      const protocol = LOADBALANCER_URL.startsWith('http:') ? 'ws' : 'wss'
      url = url || `${protocol}://${address}`
      // Initializing socket.io
      console.log(`Initializing socketio: url=${url} qp=${JSON.stringify(qp)}`)
      const { default: io } = await import('socket.io-client')
      const socket = io(url, {
        parser: SOCKETIO_USE_MSGPACK ? customParser : undefined,
        query: qp,
        autoConnect: false,
        transports: ['websocket']
      })
      const emit = socket.emit
      const ignoredEvents = new Set([
        'ping',
        'pong',
        'user-typing',
        RoomEventTypes.ROOM_CHAT_MESSAGE
      ])
      socket.emit = (evt, ...args) => {
        // Ignore ping
        if (!ignoredEvents.has(evt)) {
          Analytics.log('socket', evt)
        }
        emit.call(socket, evt, ...args)
      }

      socket.on('updated-profile', async ({ userHash, emailHash, nickname, avatar }) => {
        if (nickname) {
          if (state.room && state.room.roomUsers && state.room.roomUsers[userHash]) {
            Vue.set(state.room.roomUsers[userHash], 'nickname', nickname)
          } else {
            if (state.userProfile && state.userProfile.userHash === userHash) {
              Vue.set(state.userProfile, 'nickname', nickname)
            } else {
              APIRequest.logClientError('updated-profile.nickname.no-userHash')
            }
          }
        }
        if (avatar) {
          const blob = new Blob([avatar], {
            type: 'image/png'
          })
          const newAvatar = URL.createObjectURL(blob)
          // const newAvatar = await dispatch('fetchAvatar', emailHash)
          if (state.room && state.room.roomUsers && state.room.roomUsers[userHash]) {
            Vue.set(state.room.roomUsers[userHash], 'avatar', newAvatar)
          } else {
            if (state.userProfile && state.userProfile.userHash === userHash) {
              Vue.set(state.userProfile, 'avatar', newAvatar)
            } else {
              APIRequest.logClientError('updated-profile.avatar.no-userHash')
            }
          }
        }
      })

      socket.on('end-session', () => {
        dispatch('logout')
      })

      socket.on('authenticated', async () => {
        // TODO: Should the other socket.on(...)s be moved into this?
        console.log('Authenticated')
        // We only want to replace the socket with the new one if they are different
        // Otherwise, updateSocket will end up disconnecting the current (and only) socket
        if (replaceSocket && state.socket !== socket) {
          await dispatch('updateSocket', socket)
        }
        console.log('[socket-io] Fired socket-available event')
        socketioPromise.resolve(socket)
      })

      if (payload.beforeOpen) {
        payload.beforeOpen(socket, qp).then(() => {
          socket.open()
        })
      } else {
        socket.open()
      }
    })
    return socketioPromise.then((socket) => {
      AppBus.$emit('socket-initialized', socket)
      return socket
    })
  },
  doLogin ({ dispatch, commit, state, getters }) {
    const deferred = new Deferred()
    const onAuthenticated = async (authResult) => {
      state.authLock.off('authenticated', onAuthenticated)
      state.authLock.off('authorization_error', onError)

      // Success callback
      // Save the tokens
      console.log('Authenticated')
      const { access_token: accessToken } = authResult
      await dispatch('updateAccessToken', accessToken)
      commit('authState', authResult)
      storage.setItem('auth-accessToken', accessToken)
      if (accessToken) {
        try {
          await dispatch('getAuthProfile')
        } catch (err) {
          console.error(err && err.stack)
          // dispatch('invalidateAccessToken')
        }
      }
      deferred.resolve()
    }
    const onError = async (err) => {
      state.authLock.off('authenticated', onAuthenticated)
      state.authLock.off('authorization_error', onError)

      if (err.code === 'rule_error') {
        // This is email verification failure
        // Resend verification email
        const email = document.querySelector('input[type="email"]').value
        const response = await apiRequest('/api/resend-verification-email', {
          params: {
            email
          }
        })
        if (response.status === 200) {
          toast('Resent verification email. Remember to check your spam folder.', null, 'client-toast-above-all')
        } else {
          toast(`Failed to send verification email: ${JSON.stringify(response.body)}`)
        }
      }
      deferred.reject(err)
    }

    state.authLock.on('authenticated', onAuthenticated)
    state.authLock.once('authorization_error', onError)
    // Set up theme based on the current theme
    getters.fusionauth.opts.theme.background = state.theme === 'dark' ? Themes.darkPrimary : Themes.lightPrimary
    state.authLock.open({
      auth: {
        params: {
          state: location.href
        },
        loginAfterSignup: false,
        database: {
          loginAfterSignup: false
        }
      }
    })
    return deferred
  },
  // TODO: Remove this?
  async isAuthenticated ({ dispatch, commit, state }) {
    function __isAuthenticated () {
      return new Promise((resolve, reject) => {
        if (state.socket) {
          // We're already logged in
          resolve(true)
        }
        const credentials = state.accessToken
        if (!credentials) {
          dispatch('invalidateAccessToken')
          resolve(false)
        } else {
          dispatch('getAuthProfile').then(() => {
            resolve(true)
          }).catch((err) => {
            console.error(err && err.stack)
            resolve(false)
          })
        }
      })
    }
    return new Promise((resolve, reject) => {
      AppBus.$once('auth-profile-available', function (e, profile) {
        resolve(true)
      })
      __isAuthenticated().then((result) => {
        if (!result) {
          resolve(result)
        }
      })
    })
  },
  async updateProfileInfo ({ dispatch, commit, state }, profile) {
    if (!profile) {
      // Invalidating
      state.userProfile = undefined
      storage.removeItem('auth-userProfile')
      state.accessToken = undefined
      state.isLoggedIn = false
      return
    }
    storage.setItem('auth-userProfile', JSON.stringify(profile))
    await commit('userProfile', profile)
    commit('isLoggedIn', !!profile.nickname)
    const avatar = await dispatch('fetchAvatar', profile.emailHash)
    Vue.set(state.userProfile, 'avatar', avatar)
    AppBus.$emit('auth-profile-available', profile)
  },
  async fetchAvatar ({ state, commit, dispatch }, hash) {
    if (!hash) {
      hash = state.userProfile.emailHash
    }
    const response = await apiRequest(getGravatar(hash, 80), {
      responseType: 'arraybuffer'
    })
    const data = response.data
    const contentType = response.headers['content-type']
    const blob = new Blob([data], {
      type: contentType
    })
    const url = URL.createObjectURL(blob)
    return url
  },
  logout ({ dispatch, commit, state }) {
    dispatch('invalidateAccessToken')
    dispatch('updateProfileInfo', undefined)
    state.authLock.logout({
      returnTo: window.location.origin
    })
    if (state.socket) {
      state.socket.disconnect()
      commit('socket', undefined)
    }
    if (state.tokenRefreshTimeout) {
      clearTimeout(state.tokenRefreshTimeout)
      state.tokenRefreshTimeout = undefined
    }
    // Send user back to landing page
    AppBus.$emit('logout')
  },
  async getAuthProfile ({ dispatch, state }, profile) {
    if (profile) {
      console.error('XXX: Why is getAuthProfile being called with a profile?' + new Error().stack)
      return
    }
    const { accessToken } = state
    if (!accessToken) {
      throw new Error('No access-token')
    }
    try {
      const response = await apiRequest('/api/userinfo')
      profile = response.data
      if (profile.isPatron) {
        // Set some fields that may not be set by the backend
        if (profile.showChatGlow === undefined) {
          profile.showChatGlow = true
        }
        if (profile.showChatCrown === undefined) {
          profile.showChatCrown = true
        }
      }
      await dispatch('updateProfileInfo', profile)
      // Notify extension about credentials
      try {
        const lastLoginCreds = JSON.parse(localStorage.getItem(state.authLock.lastLoginCredentialsKey))
        // Trigger this event so that mobile users can log in
        if (isMobile() || isTablet()) {
          triggerEvent(window, 'ext:creds', lastLoginCreds)
        }
      } catch (e) {
      }
      return profile
    } catch (e) {
      console.log('Failed to get user profile from token: ' + JSON.stringify(e))
      // dispatch('invalidateAccessToken')
      throw e
    }
  },
  async invalidateAccessToken ({ state, dispatch, commit }) {
    console.log('auth-accessToken-invalid')
    await dispatch('purgeStorage')
    state.socket && state.socket.disconnect()
    commit('socket', undefined)
    commit('isLoggedIn', false)
    triggerEvent(document, 'no-accessToken') // TODO: Replace with AppBus?
  },
  purgeStorage ({ dispatch, commit }) {
    commit('userProfile', undefined)
    dispatch('updateAccessToken', undefined)
    storage.removeItem('auth-accessToken')
  },
  async initAuth ({ commit, getters }) {
    if (state.authLock) {
      return
    }
    const { default: FusionAuthLock } = await import('@twosevenxyz/fusionauth-lock')
    const { default: LoginComponent } = await import('@twosevenxyz/login-component')

    const { fusionauth } = getters
    const { applicationID, host, opts } = fusionauth
    const lock = new FusionAuthLock(applicationID, host, LoginComponent, opts)
    commit('authLock', lock)
  },
  async isAdmin ({ commit, state }) {
    const accessToken = state.accessToken || storage.getItem('auth-accessToken')
    if (!accessToken) {
      return false
    }
    // We have a token. Ensure this is a valid admin token
    const response = await apiRequest('/api/isAdmin', {
      params: {
        accessToken
      }
    })
    commit('isAdmin', response.data)
    return response.data
  },
  async updateUserSettings ({ state }, { action, value }) {
    const { socket, userProfile } = state
    return new Promise((resolve, reject) => {
      socket.emit('update-profile', { action, value }, result => {
        if (result.status === 'error') {
          const msg = result.messages.join('. ')
          return reject(new Error(msg))
        }
        for (const [k, v] of Object.entries(result.data)) {
          Vue.set(userProfile, k, v)
        }
        resolve()
      })
    })
  },
  resetReactionUrls ({ commit }) {
    commit('reactionUrls', [...defaultReactionUrls])
  },
  startIdleTimer ({ state, commit, dispatch }, timeout = 60 * 1000) {
    const now = state.idleCheck.now = Date.now()
    if (state.idleCheck.timeout && now < state.idleCheck.expiresAt) {
      return
    }
    state.idleCheck.expiresAt = now + timeout
    state.idleCheck.interval = setInterval(() => {
      state.idleCheck.now = Date.now()
    }, 300)
    state.idleCheck.timeout = setTimeout(async () => {
      dispatch('resetIdleTimer')
      await dispatch('invalidateAccessToken')
      AppBus.$emit('idle-client')
      AppBus.$emit('route:landing')
    }, timeout)
  },
  resetIdleTimer ({ state }) {
    state.socket.emit('no-op')
    state.idleCheck.expiresAt = undefined
    state.idleCheck.now = undefined
    state.idleCheck.timeout && clearTimeout(state.idleCheck.timeout)
    state.idleCheck.interval && clearTimeout(state.idleCheck.interval)
    state.idleCheck.timeout = undefined
  }
}

export default new Vuex.Store({
  state,
  actions,
  mutations,
  getters,
  modules: {
    links,
    is,
    home,
    room,
    user,
    textChat,
    simplePeer,
    videoSearch,
    social,
    localVideo,
    advancedOptions
  }
})
