import { generateGetters, generateMutations } from '../helpers'

const state = {
  localShareType: 'webtorrent',
  localFiles: undefined,
  showExtLocalFileInput: false
}

const getters = generateGetters(state)

const mutations = generateMutations(state)

export default {
  state,
  getters,
  mutations
}
