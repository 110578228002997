/* global DocumentTouch */
const freeSelf = window.document
const { navigator } = window
const platform = ((navigator && navigator.platform) || '').toLowerCase()
const userAgent = ((navigator && navigator.userAgent) || '').toLowerCase()
const vendor = ((navigator && navigator.vendor) || '').toLowerCase()

// build a 'comparator' object for various comparison checks
const comparator = {
  '<': function (a, b) { return a < b },
  '<=': function (a, b) { return a <= b },
  '>': function (a, b) { return a > b },
  '>=': function (a, b) { return a >= b }
}

// helper function which compares a version to a range
function compareVersion (version, range) {
  const string = (range + '')
  const n = +(string.match(/\d+/) || NaN)
  const op = string.match(/^[<>]=?|/)[0]
  return comparator[op] ? comparator[op](version, n) : (version == n || n !== n) // eslint-disable-line
}

export function isOpera (range) {
  const match = userAgent.match(/(?:^opera.+?version|opr)\/(\d+)/)
  return match !== null && compareVersion(match[1], range)
}

export function isChrome (range) {
  const match = /google inc/.test(vendor) ? userAgent.match(/(?:chrome|crios)\/(\d+)/) : null
  return match !== null && compareVersion(match[1], range)
}

export function isFirefox (range) {
  const match = userAgent.match(/(?:firefox|fxios)\/(\d+)/)
  return match !== null && compareVersion(match[1], range)
}

export function isEdge (range) {
  const match = userAgent.match(/edg\/(\d+)/)
  return match !== null && compareVersion(match[1], range)
}

export function isSafari (range) {
  const match = userAgent.match(/version\/(\d+).+?safari/)
  return match !== null && compareVersion(match[1], range)
}

export function isIOS () {
  return isIphone() || isIpad() || isIpod()
}

export function isIpad (range) {
  const match = userAgent.match(/ipad.+?os (\d+)/)
  return match !== null && compareVersion(match[1], range)
}

export function isIphone (range) {
  // avoid false positive for Facebook in-app browser on ipad;
  // original iphone doesn't have the OS portion of the UA
  const match = isIpad() ? null : userAgent.match(/iphone(?:.+?os (\d+))?/)
  return match !== null && compareVersion(match[1] || 1, range)
}

export function isIpod (range) {
  const match = userAgent.match(/ipod.+?os (\d+)/)
  return match !== null && compareVersion(match[1], range)
}

export function isMac () {
  return /mac/.test(platform)
}

export function isMobile () {
  return isIphone() || isIpod() || isAndroidPhone() || isBlackberry() || isWindowsPhone()
}

export function isAndroidPhone () {
  return /android/.test(userAgent) && /mobile/.test(userAgent)
}

export function isAndroidTablet () {
  return /android/.test(userAgent) && !/mobile/.test(userAgent)
}

export function isBlackberry () {
  return /blackberry/.test(userAgent) || /bb10/.test(userAgent)
}

export function isWindowsPhone () {
  return isWindows() && /phone/.test(userAgent)
}

export function isWindowsTablet () {
  return isWindows() && !isWindowsPhone() && /touch/.test(userAgent)
}

export function isWindows () {
  return /win/.test(platform)
}

export function isLinux () {
  return /linux/.test(platform) && !isAndroid()
}

export function isAndroid () {
  return /android/.test(userAgent)
}

export function isDesktop () {
  return !isMobile() && !isTablet()
}

export function isTablet () {
  return isIpad() || isAndroidTablet() || isWindowsTablet()
}

export function isTouchDevice () {
  return !!document && ('ontouchstart' in freeSelf ||
            ('DocumentTouch' in freeSelf && document instanceof DocumentTouch))
}
