import throttle from 'lodash/throttle'

const prefix = '__perf__'

function setupPerformanceObserver (cb, throttleMS) {
  let measurements = []
  const callback = throttle(() => {
    const currentMeasurements = measurements
    measurements = []
    cb(currentMeasurements)
  }, throttleMS)

  const observer = new PerformanceObserver((list) => {
    measurements.push(...list.getEntries().filter(entry => entry.name.startsWith(prefix)).map(entry => {
      const { name, duration, startTime, entryType } = entry
      return {
        name: name.replace(new RegExp(`${prefix}:`), ''),
        duration,
        startTime,
        entryType
      }
    }))
    callback()
  })
  observer.observe({ entryTypes: ['measure'] })
}

function createMarkOrMeasure (label) {
  return [prefix, label].join(':')
}

export {
  setupPerformanceObserver,
  createMarkOrMeasure,
  prefix
}
