import axios from 'axios'
let instance

async function getInstance () {
  if (!instance) {
    // const { default: axios } = await import('axios')
    const { default: FusionAuthConfig } = await import('../../static/scripts/fusionauth-config.js')
    const { opts: { storage } } = FusionAuthConfig

    instance = axios.create({
      headers: {
        Authorization: `Bearer ${storage.getItem('auth-accessToken')}`
      }
    })
  }
  return instance
}
async function apiRequest (url, params = {}, method = 'get', ...extras) {
  const instance = await getInstance()
  switch (method) {
    case 'get':
      return instance.get(url, params, ...extras)
    case 'post': {
      const { body } = params
      delete params.body
      return instance.post(url, body, params, ...extras)
    }
  }
}

async function logClientError (event) {
  // Set up counter
  const data = {
    metric: `errors.${event}`,
    type: 'increment',
    value: 1
  }
  return logClientEvent(data)
}

async function logClientEvent (data) {
  let socket
  try {
    ; ({ $store: { getters: { socket } } } = this)
  } catch (e) {
  }
  if (!Array.isArray(data)) {
    data = [data]
  }
  if (socket) {
    return new Promise(resolve => socket.emit('client-event', data, resolve))
  } else {
    return apiRequest('/api/unsecured/events/client', {
      body: data
    }, 'post')
  }
}

function install (Vue) {
  Vue.prototype.apiRequest = apiRequest
  Vue.prototype.logClientError = logClientError
  Vue.prototype.logClientEvent = logClientEvent
}

export {
  apiRequest
}

export default {
  getInstance,
  apiRequest,
  logClientError,
  logClientEvent,
  install
}
