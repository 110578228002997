export default class BaseAnalytics {
  log (...args) {
    throw new Error('Unimplemented')
  }

  async updatePage (to) {
    throw new Error('Unimplemented')
  }

  async initialize () {
    throw new Error('Unimplemented')
  }
}
