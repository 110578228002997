import Vue from 'vue'
import { generateGetters, generateMutations } from '../helpers'

const defaultVueAuth = {
  baseUrl: 'https://twoseven.xyz', // Your API domain

  providers: {
    google: {
      url: '/auth/google',
      responseType: 'token',
      authorizationEndpoint: 'https://accounts.google.com/o/oauth2/auth',
      clientId: '618563485712-meekboncn72703i0lgetq6c7jeed90d1.apps.googleusercontent.com',
      redirectUri: `${window.location.origin}/google-contacts-oauth2`,
      scope: ['profile', 'email', 'https://www.googleapis.com/auth/contacts.readonly']
    }
  }
}

const state = {
  social: {}
}

const getters = generateGetters(state)

const mutations = generateMutations(state)

const actions = {
  async initializeVueAuth (_, provider) {
    if (!Vue.prototype.axios) {
      const { default: axios } = await import('axios')
      const { default: VueAxios } = await import(/* webpackChunkName: "vue-axios" */ 'vue-axios')
      Vue.use(VueAxios, axios)
    }
    const { VueAuthenticate } = await import(/* webpackChunkName: "vue-authenticate" */ '@gurupras/vue-authenticate')
    const vueAuth = new VueAuthenticate(Vue.prototype.$http, provider)
    Vue.use(vueAuth)
    return vueAuth
  },
  async authenticate ({ state, dispatch }, provider) {
    const vueAuth = await dispatch('initializeVueAuth', defaultVueAuth)
    return new Promise((resolve, reject) => {
      console.log(`Attempting to authenticate with provider ${provider}`)
      vueAuth.authenticate(provider).then((results) => {
        state.social[provider] = results
        console.log(`Authenticated with provider ${provider}`)
        resolve(results)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  async authenticateYoutube ({ state, dispatch }) {
    const provider = 'google'
    console.log(`Attempting to authenticate with provider ${provider}`)
    const youtubeProvider = JSON.parse(JSON.stringify(defaultVueAuth))
    // TODO: Modify youtubeProvider
    youtubeProvider.providers.google.scope = [
      'https://www.googleapis.com/auth/youtube.readonly'
    ]
    const vueAuth = await dispatch('initializeVueAuth', youtubeProvider)
    const results = await vueAuth.authenticate(provider)
    state.social.youtube = results
    console.log(`Authenticated with provider ${provider}`)
    return results
  },
  async loadGoogleContacts ({ state }) {
    return new Promise((resolve, reject) => {
      const token = state.social.google
      const gapi = window.gapi
      gapi.load('client', {
        async callback () {
          // Handle gapi.client initialization.
          gapi.client.setToken(token)
          const response = await gapi.client.request({
            path: 'https://people.googleapis.com/v1/people/me/connections',
            params: {
              personFields: ['names', 'emailAddresses'],
              pageSize: 2000
            }
          })
          const { default: md5 } = await import('md5')
          const result = JSON.parse(response.body)
          const { connections } = result
          const peopleWithEmail = []
          connections.forEach(conn => {
            if (conn.emailAddresses) {
              conn.md5 = md5(JSON.stringify(conn))
              peopleWithEmail.push(conn)
            }
          })
          resolve(peopleWithEmail)
        },
        onerror: function () {
          // Handle loading error.
          alert('gapi.client failed to load!')
        },
        timeout: 5000, // 5 seconds.
        ontimeout: function () {
          // Handle timeout.
          alert('gapi.client could not load in a timely manner!')
        }
      })
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
