class OrderedData {
  constructor (data, key = 'id') {
    this.data = {}
    this.order = []
    this.key = key
    for (const entry of data) {
      const { [key]: keyValue } = entry
      if (!keyValue) {
        throw new Error('Bad key')
      }
      this.data[keyValue] = entry
      this.order.push(keyValue)
    }
  }

  get array () {
    const { data, order } = this
    return order.map(key => data[key])
  }

  remove (key) {
    let obj
    if (typeof key === 'object') {
      obj = key
      key = this.getKeyValue(obj)
    }
    const { data, order } = this
    delete data[key]
    const idx = order.indexOf(key)
    if (idx > -1) {
      order.splice(idx, 1)
    }
  }

  update (data) {
    const keyValue = this.getKeyValue(data)
    this.data[keyValue] = data
  }

  getKeyValue (obj) {
    const { key } = this
    const { [key]: keyValue } = obj
    return keyValue
  }
}

export class VueOrderedData extends OrderedData {
  constructor (data, key, Vue) {
    super(data, key)
    this.Vue = Vue
  }

  push (data) {
    const { Vue } = this
    const keyValue = this.getKeyValue(data)
    Vue.set(this.data, keyValue, data)
    this.order.push(keyValue)
  }

  update (data) {
    const keyValue = this.getKeyValue(data)
    if (!this.data[keyValue]) {
      return this.push(data)
    }
    super.update(data)
  }
}

export default OrderedData
