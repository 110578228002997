const privilegesArray = [
  'EARLY_ACCESS',
  'CHAT_GLOW',
  'CHAT_CROWN',
  'CHAT_GLOW_MODIFY',
  'REACTIONS',
  'REACTIONS_MODIFY',
  'TEXT_CHAT_PIN_MESSAGES',
  'TEXT_CHAT_EDIT_MESSAGES',
  'TEXT_CHAT_DELETE_MESSAGES',
  'VIDEO_SELECTOR_HULU',
  'VIDEO_SELECTOR_DISNEY+',
  'VIDEO_SELECTOR_SCREENSHARE',
  'ROOM_CONFIGURE_METADATA',
  'SCREENSHARE_RESOLUTION_1080P',
  'SFU_PARTICIPANT_LIMIT_10',
  'SFU_PARTICIPANT_LIMIT_20',
  'SFU_PARTICIPANT_LIMIT_40',
  'SFU_PARTICIPANT_LIMIT_100000',
  'SFU_FOR_VIDEO_CHAT',
  'FEATURE_PLAYLIST',
  'FRAME_BY_FRAME_SEEK',
  'OWNER_ROOM_LIMIT_10'
]

const allRoomPrivilegesSet = new Set([
  'REACTIONS',
  'VIDEO_SELECTOR_HULU',
  'VIDEO_SELECTOR_DISNEY+',
  'VIDEO_SELECTOR_SCREENSHARE',
  'SFU_PARTICIPANT_LIMIT_10',
  'SFU_PARTICIPANT_LIMIT_20',
  'SFU_PARTICIPANT_LIMIT_40',
  'SFU_PARTICIPANT_LIMIT_100000',
  'SFU_FOR_VIDEO_CHAT',
  'FEATURE_PLAYLIST',
  'FRAME_BY_FRAME_SEEK'
])

const privileges = {}
privilegesArray.forEach(privilege => { privileges[privilege] = privilege })

/**
 * Special privilege that denotes all privileges
 */
Object.defineProperty(privileges, 'all', {
  enumerable: false,
  value: 'ALL'
})

export {
  privileges,
  allRoomPrivilegesSet
}
