import BaseAnalytics from './base-analytics'

export default class PlausibleAnalytics extends BaseAnalytics {
  async initialize () {
    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        // Did not get a response
        clearInterval(interval)
        return reject(new Error('Unable to initialize GoogleAnalytics'))
      }, 10000)

      const interval = setInterval(() => {
        const plausible = window.plausible
        if (plausible) {
          this.client = plausible
          clearTimeout(timeout)
          return resolve()
        }
      }, 500)
    })
  }

  async log (category, action, label, value) {
    const { client } = this
    try {
      client(category, {
        props: {
          action,
          label,
          value
        }
      })
    } catch (e) {
    }
  }

  async updatePage () {
    // We don't need to do this
  }
}
