export default {
  applicationID: 'ac6ab5ba-3bf2-4f87-a95d-cccf8424844f',
  host: window.location.host,
  opts: {
    appName: 'TwoSeven',
    logo: 'https://twoseven.xyz/img/icons/android-chrome-512x512.png',
    loginUri: `${window.location.origin}/api/login`,
    storage: window.localStorage,
    links: {
      tos: `${window.location.origin}/help/tac`,
      privacyPolicy: `${window.location.origin}/help/privacy-policy`
    },
    auth: {
      redirectUri: window.location.origin,
      scope: 'openid',
      device: {
        type: 'UNKNOWN'
      }
    },
    social: {
      baseUrl: window.location.origin, // Your API domain
      storageNamespace: 'vue-authenticate-website',
      providers: {
        google: {
          url: '/auth/google',
          responseType: 'code',
          // authorizationEndpoint: 'https://accounts.google.com/o/oauth2/v2/auth',
          clientId: '618563485712-blhnil48dv8l9rkkjrbdhsgn6k5iv7vo.apps.googleusercontent.com',
          redirectUri: `${window.location.origin}/auth/google`,
          scope: ['profile', 'email', 'openid'],
          optionalUrlParams: ['state', 'prompt'],
          prompt: 'select_account',
          nonce: function () {
            return encodeURIComponent(`${Date.now() + (Math.random() * 1e6 | 0)}`)
          }
        },
        facebook: {
          responseType: 'code',
          clientId: '1199003450182948',
          redirectUri: `${window.location.origin}/auth/facebook`,
          scope: ['email'],
          optionalUrlParams: ['state']
        }
      }
    },
    theme: {
      background: '#009688',
      text: '#fff',
      invertedText: '#000'
    }
  }
}
