
import Vue from 'vue'

import router from './router.js'
import store from './store/index.js'

import { toast } from '@/js/utils.js' // eslint-disable-line no-unused-vars

import { setupPerformanceObserver } from '@/js/perf/client.js'
import i18n from '@/store/i18n/index.js'

Vue.prototype.i18n = i18n

Vue.config.productionTip = false

const url = new URL(window.location.href)
const { searchParams } = url
const ref = searchParams.get('ref')
const referral = searchParams.get('referral')
if (ref) {
  // We have a referer
  searchParams.delete('ref')
  console.log(`Removed ref: ${ref}`)
}
searchParams.delete('referral')
try {
  window.history.replaceState(null, '', url.toString())
} catch (e) {
}

;(async () => {
  import(/* webpackChunkName: "materialize-css" */ 'materialize-css/dist/css/materialize.min.css') // eslint-disable-line

  const promises = [
    import(/* webpackChunkName: "vue-app" */ './App.vue'),
    import(/* webpackChunkName: "static-plugins" */ './static-plugins'),
    import(/* webpackChunkName: "materialize-js" */ 'materialize-css/dist/js/materialize.min.js')
  ]

  const [
    { default: App }
  ] = await Promise.all(promises)

  const app = window.app = new Vue({
    el: '#app',
    router,
    store,
    template: '<App/>',
    components: { App }
  })
  if (referral) {
    window.app.$cookie.set('referralID', referral, { expires: '3D' })
  }
  setupPerformanceObserver(app.$logPerformance.bind(app), 3000)

  import(/* webpackChunkName: "plyr-css" */ '@twosevenxyz/plyr/src/sass/plyr.scss') // eslint-disable-line

  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    if (registrations.length > 0) {
      for (const registration of registrations) {
        registration.unregister()
      }
      console.log('unregistered all service workers')
    }
  })

  // if (process.env.VUE_APP_MODE === 'dev') {
  //   window.tests = await import(/* webpackChunkName: "tests" */ '@/js/tests.js')
  //   window.M = M
  //   window.toast = toast
  // }
})()
