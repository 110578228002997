const getters = {
  chromeExtensionURL: state => 'https://chrome.google.com/webstore/detail/twoseven-extension/cjdnfmjmdligcpfcekfmenlhiopehjkd',
  firefoxExtensionURL: state => 'https://addons.mozilla.org/en-US/firefox/addon/twosevenxyz-extension/',
  edgeExtensionURL: state => 'https://microsoftedge.microsoft.com/addons/detail/ijmgabkialjfgihheolilfhcefheabok',
  operaExtensionURL: state => 'https://addons.opera.com/en/extensions/details/twoseven-extension/',
  patreonURL: state => 'https://www.patreon.com/bePatron?u=17171070',
  kofiURL: state => 'https://ko-fi.com/twosevenxyz'
}

export default {
  getters
}
