import Vue from 'vue'
import { generateGetters, generateMutations, illegalMutation } from '../helpers'
import AppBus from '@C/bus/app-bus.vue'

const state = {
  webcamWebRTC: undefined,
  screenWebRTC: undefined,
  webRTCReady: false,
  getUserMediaLocked: false,
  remoteWebcamStreams: {},
  selfAVStream: undefined,
  selfScreenStream: undefined,
  remoteScreenStream: undefined,
  webRTCLock: undefined,
  numConsumers: 0,
  webRTCClaim: undefined,
  videoChatVolumes: {},
  mediaDevices: []
}

const getters = generateGetters(state)
const mutations = {
  ...generateMutations(state),
  numConsumers: illegalMutation,
  remoteScreenStream (state, entry) {
    if (entry) {
      const { metadata: { userIdentifier } } = entry
      entry.controls = addControls(() => state.screenWebRTC, userIdentifier, 'screen')
    }
    state.remoteScreenStream = entry
  }
}

const actions = {
  async initializeWebRTCLock ({ state, commit }) {
    const { default: AsyncLock } = await import('async-lock')
    if (!state.webRTCLock) {
      commit('webRTCLock', new AsyncLock())
    }
  },
  async relinquishClaim ({ state, commit, rootState }) {
    const { webRTCClaim } = state
    const { socket, sessionID } = rootState
    if (!socket || !webRTCClaim) {
      return
    }
    socket.emit('mediasoup:resource-relinquish', { claim: webRTCClaim, sessionID })
    commit('webRTCClaim', undefined)
    console.log('[webrtc]: Relinquished claim')
  },
  incrementConsumers ({ state }, v) {
    state.numConsumers += v
    console.log(`[webrtc]: # consumers: ${state.numConsumers}`)
  },
  decrementConsumers ({ state, dispatch }, v) {
    state.numConsumers -= v
    console.log(`[webrtc]: # consumers: ${state.numConsumers}`)
    if (state.numConsumers === 0) {
      return dispatch('relinquishClaim')
    }
  },
  async updateRemoteWebcamStreams ({ state, getters }, peerMap) {
    const { remoteWebcamStreams, videoChatVolumes } = state
    const { userIdentifier: selfIdentifier } = getters
    Object.keys(peerMap).forEach(userIdentifier => {
      const existingEntry = remoteWebcamStreams[userIdentifier]
      if (existingEntry) {
        // This is a peer that's now shown up. Reset their reconnecting flag to false
        existingEntry.reconnecting = false
      }
      // We only create entries for non-existing, non-self entries
      if (userIdentifier === selfIdentifier || existingEntry) {
        return
      }
      Vue.set(remoteWebcamStreams, userIdentifier, {
        controls: addControls(() => state.webcamWebRTC, userIdentifier, 'webcam'),
        userIdentifier,
        metadata: {},
        hasVideo: false,
        hasAudio: false,
        stream: undefined,
        streamPrefs: {
          mic: true,
          video: true,
          volume: true
        },
        displayPrefs: {
          disableVideo: false,
          disableAudio: false
        },
        reconnecting: false // This flag is used to allow the UI to show some kind of UI affordance
      })
      Vue.set(videoChatVolumes, userIdentifier, -999)
      AppBus.$emit('remoteWebcamStreams:add', userIdentifier)
    })
  },
  async updateMediaDevices ({ commit }) {
    const devices = await navigator.mediaDevices.enumerateDevices()
    commit('mediaDevices', devices)
  }
}

function addControls (getWebRTC, userIdentifier, type) {
  return {
    makeRequest: (fnLabel, kind) => {
      const webRTC = getWebRTC()
      if (webRTC === null) {
        // Special value for when webRTC is re-initializing
        return
      }
      return webRTC && webRTC[fnLabel](type, kind, userIdentifier)
    },
    volume: async (volume) => {
      try {
        const webRTC = getWebRTC()
        if (webRTC === null) {
          // Special value for when webRTC is re-initializing
          return
        }
        webRTC && await webRTC.updateVolume(volume, userIdentifier, type)
      } catch (e) {
        console.error(e)
      }
    },
    pauseVideoProducer () {
      return this.makeRequest('pauseProducer', 'video')
    },
    pauseAudioProducer () {
      return this.makeRequest('pauseProducer', 'audio')
    },
    resumeVideoProducer () {
      return this.makeRequest('resumeProducer', 'video')
    },
    resumeAudioProducer () {
      return this.makeRequest('resumeProducer', 'audio')
    },
    pauseVideoConsumer () {
      return this.makeRequest('pauseConsumer', 'video')
    },
    pauseAudioConsumer () {
      return this.makeRequest('pauseConsumer', 'audio')
    },
    resumeVideoConsumer () {
      return this.makeRequest('resumeConsumer', 'video')
    },
    resumeAudioConsumer () {
      return this.makeRequest('resumeConsumer', 'audio')
    }
  }
}
export default {
  state,
  getters,
  mutations,
  actions
}
