import Vue from 'vue'
import Router from 'vue-router'
import Analytics from '@/js/analytics'

const LandingPage = () => import(/* webpackChunkName: "view-landing-page" */ '@C/landing-page.vue')
const HomePage = () => import(/* webpackChunkName: "view-home-page" */ '@C/home-page.vue')
const Room = () => import(/* webpackChunkName: "view-room" */ '@C/room.vue')

const LoadExtensionMedia = () => import(/* webpackChunkName: "view-load-extension-media" */ '@C/load-extension-media.vue')
const LoadNonExtensionMedia = () => import(/* webpackChunkName: "view-load-non-extension-media" */ '@C/load-non-extension-media.vue')
const GetExtension = () => import(/* webpackChunkName: "view-get-extension" */ '@C/get-extension.vue')
Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'landingPage',
      alias: ['/land'],
      component: LandingPage
    },
    {
      path: '/home',
      name: 'homePage',
      component: HomePage
    },
    {
      path: '/ext-media',
      name: 'extMedia',
      component: LoadExtensionMedia
    },
    {
      path: '/non-ext-media',
      name: 'nonExtMedia',
      component: LoadNonExtensionMedia
    },
    {
      path: '/admin',
      name: 'admin',
      component: () => import(/* webpackChunkName: "view-admin" */ '@C/admin/admin.vue'),
      children: [
        {
          path: 'app-status',
          component: () => import(/* webpackChunkName: "view-app-status" */ '@C/admin/app-status.vue')
        },
        {
          path: 'url-creator',
          component: () => import(/* webpackChunkName: "view-url-creator" */ '@C/admin/url-creator.vue')
        },
        {
          path: 'session-explorer',
          component: () => import(/* webpackChunkName: "view-session-explorer" */ '@C/admin/sessions/session-explorer.vue')
        },
        {
          path: 'coverage',
          component: () => import(/* webpackChunkName: "view-coverage" */ '@C/admin/coverage.vue')
        },
        {
          path: 'stats',
          component: () => import(/* webpackChunkName: "view-stats" */ '@C/admin/stats.vue')
        },
        {
          path: 'current-state',
          component: () => import(/* webpackChunkName: "view-current-state" */ '@C/admin/current-state.vue')
        },
        {
          path: 'manage-patrons',
          component: () => import(/* webpackChunkName: "view-manage-patrons" */ '@C/admin/manage-patrons.vue')
        },
        {
          path: 'health',
          component: () => import(/* webpackChunkName: "view-health" */ '@C/admin/health.vue')
        }
      ],
      async beforeEnter (to, from, next) {
        function kick () {
          // Kick this client out
          router.app.$session.destroy()
          return next('/')
        }
        const { default: store } = await import(/* webpackChunkName: "store" */ '@/store/index.js')
        store.dispatch('isAdmin').then(result => result ? next() : kick())
      }
    },
    {
      path: '/get-extension',
      name: 'getExtension',
      component: GetExtension
    },
    {
      path: '/help/faq',
      name: 'faq',
      alias: ['/pages/faq', '/pages/help/faq'],
      component: () => import('@/pages/faq/FAQ.vue')
    },
    {
      path: '/help/tac',
      name: 'tac',
      alias: ['/help/terms-and-conditions', '/help/terms', '/pages/terms-and-conditions', '/pages/tac', '/pages/terms', '/pages/help/terms-and-conditions', '/pages/help/terms', '/pages/help/tac'],
      component: () => import('@/pages/terms-and-conditions/Terms.vue')
    },
    {
      path: '/help/privacy-policy',
      name: 'privacy-policy',
      alias: ['/pages/privacy-policy', '/pages/help/privacy-policy'],
      component: () => import('@/pages/privacy-policy/PrivacyPolicy.vue')
    },
    {
      path: '/help/licenses',
      name: 'licenses',
      alias: ['/pages/licenses', '/pages/help/licenses'],
      component: () => import('@/pages/licenses/Licenses.vue')
    },
    {
      path: '/help/iframe-element',
      name: 'iframe-element',
      alias: ['/pages/help/iframe-element'],
      component: () => import('@/pages/iframe-element/App.vue')
    },
    {
      path: '/pages/supporters',
      name: 'supporters',
      component: () => import('@/pages/supporters/Supporters.vue')
    },
    {
      path: '/pages/login',
      name: 'login-page',
      component: () => import('@/pages/login/Login.vue')
    },
    {
      path: '/*',
      name: 'roomPage',
      component: Room
    }
  ]
})

router.afterEach((to, from) => {
  Analytics.updatePage(to.path)
})

export default router
